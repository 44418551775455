import { createApp } from 'vue'
import App from './App.vue'

import router from "./routers";

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style.css'

import jQuery from 'jquery'
window.$ = jQuery
import 'bootstrap/dist/js/bootstrap.min.js'


const app = createApp(App);
app.use(router)
app.mount('#app')
