import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
// import Home from "../views/Home_local.vue";
import Detail from "../views/Detail.vue";
// import Detail from "../views/Detail_local.vue";

const routes = [
    {
        path:'/',
        component:Home,
    },
    {
        path:'/detail/:id/:pimpinan',
        component:Detail,
    },

]

const router = createRouter({
    history:createWebHistory(),
    routes,
})

export default router;