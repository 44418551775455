<template>
  <div class="layer">
    <div class="isContainer">
      <div class="row my-5">
        <div class="col-md-4 side-left">
          <div class="row">

            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4 text-center">
                      <img src="../assets/img/logo.png" class="img-logo" alt="">
                    </div>
                    <div class="col-lg">
                      <div class="clock-border">
                        <div class="clock-inner" :class="color">
                          <div class="hour">{{hours}}</div>
                          <div class="dots">:</div>
                          <div class="min">{{minutes}}</div>
                          <div class="dots">:</div>
                          <div class="secs">{{seconds}}</div>
                          <div class="mode"></div>
                        </div>
                      </div>


                      <div class="text-center">
                        {{days.day}}, <br>
                        {{days.date}} - {{days.month}} -{{days.year}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mt-2">
              <div class="card">
                <div class="card-body mx-auto my-2">
                  <img class="img-pejabat" :src="'https://sifsum.com/foto_pejabat/'+leader.foto" alt="">
                </div>
              </div>
            </div>

            <div class="col-lg-12 my-2">
              <div class="card">
                <div class="card-body">
                  <div class="card-title">
                    <h5>{{leader.nama_pimpinan}}</h5>
                    <h6>{{leader.nip}}</h6>
                    <p>{{leader.jabatan}}</p>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>

        <!-- RIGHT SIDE -->
        <div class="col-md-8">
          <div class="row">
            <div class="card col-lg">
              <div class="card-body">
                <h5 class="card-title mb-4 mt-2 text-center">JADWAL PIMPINAN FAKULTAS SASTRA</h5>

                <table class="table table-sm" id="tb_pimpinan">
                  <thead class="table-primary">
                    <tr>
                      <th>TANGGAL</th>
                      <th>JAM</th>
                      <th>AGENDA</th>
                      <th>TEMPAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="undangan in invitations" :key="undangan">
                      <td style="width:15%">{{undangan.tanggal}}</td>
                      <td style="width:15%">{{undangan.jam_mulai}} - {{undangan.jam_selesai}}</td>
                      <td id="noSize" style="width:50%" v-html="undangan.acara" ></td>
                      <td style="width:20%">{{undangan.tempat}}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {

    props: {
      color: {
        type: String,
        default: 'red'
      }
    },

    data() {
      return {
        leader: [],
        invitations: [],
        hours: 0,
        minutes: 0,
        seconds: 0,
        days: {
          day: '',
          date: '',
          month: '',
          year: ''
        },
        token:'api'
      }
    },

    methods: {
      setLeader(data) {
        this.leader = data.pimpinan
        this.invitations = data.agenda.data
        // console.log(data)
      },

      setTime() {
        setInterval(() => {
          const date = new Date()
          this.hours = date.getHours()
          this.minutes = this.checkSingleDigit(date.getMinutes())
          this.seconds = this.checkSingleDigit(date.getSeconds())
        }, 1000)
        const date = new Date()
        var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September',
          'Oktober', 'November', 'Desember'
        ];
        var days_w = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

        this.days.day = days_w[date.getDay()]
        this.days.date = date.getDate()
        this.days.month = months[date.getMonth()]
        this.days.year = date.getFullYear()
      },

      checkSingleDigit(digit) {
        return ('0' + digit).slice(-2)
      },

      // setUndangan() {
      //   setInterval(() => {
      //     axios.get('https://sifsum.com/api/leaders/' + this.$route.params.id + '/' + this.$route
      //         .params.nama_pimpinan + '/show')
      //       .then((response) => {
      //         console.log(response)
      //         this.setLeader(response.data)
      //       })
      //       .catch((error) => console.log(error))
      //   }, 5000)
      // }

      setAgenda(){
        setInterval(() => {
          axios.get('https://localhost/api_agenda/public/api/pull-server/'+this.$route.params.id+'/data/'+localStorage.getItem("_token_server"))
          .then((response) => {
            this.setLeader(response.data)
          })
          .catch((error) => console.log(error))
        }, 300000)
      }
    },
    mounted() {
      // this.setTime()
      // this.setUndangan()

      // axios.get('https://sifsum.com/api/leaders/' + this.$route.params.id + '/' + this.$route
      //   .params.nama_pimpinan + '/show')
      //   .then((response) => {
      //     this.setLeader(response.data)
      //   })
      //   .catch((error) => console.log(error))

      this.setTime()
      this.setAgenda()
      // this.setUndangan();
      // localStorage.setItem('_token_server','token_is_store')
      if (!localStorage.getItem("_token_server")) {
        // console.log("Undefined")
        axios.get('https://api-server.sifsum.com/api/pull-server/' + this.$route.params.id + '/data/' + this.token)
          .then((response) => {
            localStorage.setItem("_token_server", response.data.agenda.server_token)
            this.setLeader(response.data)
          })
          .catch((error) => console.log(error))
      }

      axios.get('https://api-server.sifsum.com/api/pull-server/' + this.$route.params.id + '/data/' + localStorage
          .getItem("_token_server"))
        .then((response) => {
          console.log(response)
          this.setLeader(response.data)
        })
        .catch((error) => console.log(error))


    }

  }
</script>

<style>
  .img-pejabat {
    height: 200px;
  }

  .img-logo {
    height: 80px;
  }

  #tb_pimpinan {
    font-size: 13px;
  }

  .hour,
  .min,
  .secs {
    font-size: 30px;
    font-weight: bold;
  }

  /* strong {
    color: blue;
  } */
  p {
    /* font-family: 'Lucida Sans', sans-serif; */
    font-size: 20px;
  }

  .clock-border {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 700px;
    height: 200px; */
    /* background: linear-gradient(to right, grey, rgb(82, 76, 76)); */
  }

  .clock-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 600px;
    height: 150px; */
    /* background: black; */
    border-radius: 20px;
    color: red;
  }

  .dots {
    font-size: 30px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .red {
    color: rgb(5, 5, 5);
  }

  .yellow {
    color: yellow;
  }

  .green {
    color: green;
  }
  
  .isContainer{
    padding-left: 10px;
    padding-right: 30px;
  }

#noSize {
    font-weight: 100;
    font-size: 5;
  }
</style>