<template>
  <div class="layer">
    <div class="section">
    <div class="container">
      <div class="row">
      <div class="col-md-2 mt-3 mr-auto ml-auto" v-for="leader in leaders" :key="leader">
        <div class="card">
          <img :src="'https://sifsum.com/foto_pejabat/'+leader.foto" alt="">
          <div class="card-body">
            <h6>{{leader.nama_pimpinan}}</h6>
            <router-link :to="'/detail/'+leader.id+'/'+leader.nama_pimpinan" class="btn btn-info btn-sm rounded-pill">Tampilkan</router-link>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        leaders:[]
      }
    },
    methods:{
      setLeader(data){
        console.log(data)
        this.leaders = data
      }
    },
    mounted(){
      axios.get('https://sifsum.com/api/leaders')
      .then((res)=>{
        this.setLeader(res.data);
      })
      .catch((error)=>console.log(error))
    }

  }
</script>

<style>

</style>